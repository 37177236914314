<template>
  <el-backtop :right="100" :bottom="100" >
  <div
      style="
        height: 100%;
        width: 100%;
        background-color: #fff;
        color: var(--fourth-color);
        box-shadow: #423e34;
        text-align: center;
        line-height: 40px;
        border-radius: 20px;
        font-size: large;
      "
    >
      ↑
    </div>
  </el-backtop>
  <nav class="navbar">
    <router-link to="/" class="nav-link" style="margin-left: 20px;">
      Home
      <!--
      <img class="homeicon" src="../src/assets/img04.png" alt="Home">
      -->
    </router-link> 
    <router-link to="/about" class="nav-link">活動紹介</router-link>
    <router-link to="/event" class="nav-link">わせだ寄席</router-link>
    <router-link to="/joinus" class="nav-link">新規入会</router-link>
    <router-link to="/contact" class="nav-link" >出演依頼</router-link>
    <a href="https://x.com/waseda_rakugo" class="nav-icon" target="_blank">
      <font-awesome-icon :icon="['fab', 'twitter']" />
    </a>
    <a href="https://instagram.com/waseda_rakken" class="nav-icon" target="_blank">
      <font-awesome-icon :icon="['fab', 'instagram']" />
    </a>
    <a href="https://www.youtube.com/@user-xr9xh5wv2n" class="nav-icon" target="_blank">
      <font-awesome-icon :icon="['fab', 'youtube']" />
    </a>
  </nav>
  <router-view class="routerview" v-slot="{Component}">
    <br>
  <transition>
    <component :is="Component"></component>
  </transition>
  </router-view>

  <footer>
    <br>
    <a href="https://x.com/waseda_rakugo" class="footer-icon" target="_blank">
      <font-awesome-icon :icon="['fab', 'twitter']" />
    </a>
    <a href="https://instagram.com/waseda_rakken" class="footer-icon" target="_blank">
      <font-awesome-icon :icon="['fab', 'instagram']" />
    </a>
    <a href="https://www.youtube.com/@user-xr9xh5wv2n" class="footer-icon" target="_blank">
      <font-awesome-icon :icon="['fab', 'youtube']" />
    </a>
    <br><br>
    <small>
      <p>〒162-8644 東京都新宿区戸山1-24-1 <br>早稲田大学学生会館C-21 早稲田大学落語研究会</p>
    <p>©2024 早稲田大学落語研究会</p>
  </small>
  </footer>
</template>

<script>
export default {
  name: 'MainLayout'
}
</script>

<style lang="scss">

.navbar {
  background-color: var(--header-color);
  display: center;
  justify-content: center;
  position: fixed;
  z-index: 1000;
  height: 50px;
  font-size: small;
  width: 100%;
  padding-left: 20px;
  padding-top: 10px;
}

.nav-link {
  padding-right: 10px;
  padding-left: 10px;
  color: var(--secondary-color);
  text-decoration: none;
  transition: color 0.3s;
}

.nav-link.router-link-exact-active {
  color: var(--fourth-color);
}

.nav-link:hover {
  color: var(--fourth-color); /* ホバー時にリンクの色を変更 */
  border-bottom: 2px solid var(--fourth-color);
  transform: scale(1.5);
}

.nav-icon {
  margin-left: 20px; /* アイコン間にスペースを追加 */
  color:var(--secondary-color);
  text-decoration: none;
  transition: color 0.3s;
  font-size: 15px;
}

.nav-icon:hover {
  color: var(--fourth-color); /* ホバー時にリンクの色を変更 */
  transform: scale(1.5);
}

.nav-icon:first-child {
  margin-left: 50px; /* 最初のアイコンの前に追加のスペース */
}

.homeicon{
  height: 50px;
  width: 50px;
  margin: -10px -10px -20px -10px;
}

footer{
  background-color: var(--header-color);
  color: var(--secondary-color);

  text-align: center;
  width: 100%;
}

.footer-icon{
  margin: 30px;
  color: var(--secondary-color);
  font-size: 25px;
}
.footer-icon:hover {
  color: var(--fourth-color); /* ホバー時にリンクの色を変更 */
  transform: scale(1.5);
}

@media screen and (max-width: 640px) {
  .navbar{
    position: relative;
    padding-left: 0px;
  }
  .nav-link {
    margin-right: 2px;
    margin-left: 2px;
    font-size: small;
    padding: 5px;
  }
  .nav-icon {
    display: none;
  }
  .routerview{
    width: 100%;
  }
}
</style>
