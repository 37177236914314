<template>
  <div class="freshmen">
    <br>
    <h2>入部希望者</h2>
          <p>
            他大生や２年生以上も歓迎しており、新歓期に限らず1年中入会可能です!<br>
            実際、夏秋冬ごろから来だす部員が多いです。
            <br>他のサークルに疲れたら来てください。
          </p>
          <ul class="together">
            <li>部室：早稲田大学学生会館Ｅ６１４（６階）</li>
            <li>活動日：水、土(主に午後から)</li>
            <li>主な活動場所：早稲田大学学生会館108、107号室</li>
          </ul>
          <p>
            時間がある時にはお気軽に遊びにきて下さい!!<br>
            活動していない場合もあるので、事前にメール等で連絡をいただけると幸いです!
          </p>
          <br>
          <div class="contactinfo">
          <h4>連絡先</h4>
          <p>メール<br> <a href="mailto:waseda.rakken@gmail.com">waseda.rakken@gmail.com</a></p>
          <br>
          <a>各種SNSでも!</a><br>
          <a href="https://x.com/waseda_rakugo" class="sns-icon" target="_blank">
            <font-awesome-icon :icon="['fab', 'twitter']" />
          </a>
          <a href="https://instagram.com/waseda_rakken" class="sns-icon" target="_blank">
            <font-awesome-icon :icon="['fab', 'instagram']" />
          </a>
          <br>
          <a>ご連絡お待ちしています!!</a>
          <br><br>
          </div>
  </div>
</template>

<style scoped>
.freshmen{
  text-align: left;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 400px;
  color: var(--secondary-color);
  background-color: var(--primary-color);
  width: 100%;
}


.freshmen::after{
  content: "";
  position: fixed;
  left: 0;
  top: 300px;
  width: 100%;
  height: 100%;
  background: url(../assets/img25.jpg) no-repeat center/cover;
  z-index: -1;
  opacity: 0;
  animation: fadeIn 3s forwards;
}

@keyframes fadeIn {
  from{opacity: 0;}
  to{opacity: 1;}  
}

.together{
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  width: 400px;
}

.contactinfo{
  text-align: center;
}

.sns-icon{
  margin: 30px;
  color: var(--secondary-color);
}

@media screen and (max-width: 640px) {
  .freshmen{
    overflow-x: hidden;
  }
  ul, li{
    max-width: 80%;
  }
}
</style>