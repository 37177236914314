<template>
  <div class="temp">
    <div class="inahotei">
    <h1>早稲田祭2024企画「いなほ亭」</h1>
          <h2>入場無料！！</h2>
          <p>
            開催日程
          </p>
          <h3>Coming soon...</h3>
          <br>
          <div class="concept_video00">
            <p>Concept Video (2022)</p>  
            <br>
          <iframe width="710px" height="399px" src="https://www.youtube.com/embed/tDAaLD27jSY?si=qJFMZzz91uHQ0XDe&autoplay=1&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
    </div>

    <div class="map">
      <h2>会場はこちら</h2>
    </div>
    </div>

</template>

<script>
export default {
  name: 'TempView',
  components: {
  },
  data() {
    return {
      photos: [
        require('@/assets/img00.jpg'),
        require('@/assets/img01.jpg'),
        require('@/assets/img02.jpg'),
        // 追加の写真パスをここに追加
      ],
      currentPhotoIndex: 0
    };
  },
  mounted() {
    // 最初の写真を表示
    this.changePhoto();

    // 5秒ごとに写真を切り替える
    setInterval(this.changePhoto, 5000);
  },
  methods: {
    changePhoto() {
      this.currentPhotoIndex = (this.currentPhotoIndex + 1) % this.photos.length;
    }
  }
};
</script>

<style scoped>
.temp{
  margin-top: 50px;
  padding-left: 20px;
  box-sizing: border-box;
}

.concept_video00{
  padding-left: 50px;
}

.concept_video00 p{
  display: inline-block;
}

.concept_video00 iframe{
  margin-left: auto;
  margin-right: auto;
}
.map{
  text-align: center;
}

@media screen and (max-width: 640px) {
  .concept_video00{
    display: none;
  }
}
</style>