<template>
  <div class="event">
    <br>
    <h2>わせだ寄席</h2>
          <h4>学生限定・入場無料！！</h4>
          <p>
            早稲田大学落語落語研究会では、学生の皆さんに落語の面白さを知ってもらうため、<br>
            昭和２３年から現在にいたるまで、年に１～２回、プロの落語家さんをお呼びして<br>
            「落語研究会」、のちに「わせだ寄席」と称する落語会を開催しています。<br><br>
          </p>
    </div>

    <div class="eventinfo">
      <br><br>
      <h2>本年度のわせだ寄席</h2>
      <!--
      <h4 style="color: var(--fourth-color); padding-left: 20px;">Coming soon...</h4>
      -->
      <router-link to="/thisyear" class="nav-link">詳しくはこちら。</router-link>
      <br>
      <div class="map">
        <h5>会場はこちら</h5>
      <p><iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12958.939256065507!2d139.71047874879133!3d35.708142861252554!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188d1cb93f8869%3A0x6067ebc016cae4d5!2z5bCP6YeO6KiY5b-16Kyb5aCC!5e0!3m2!1sja!2sjp!4v1720270856110!5m2!1sja!2sjp" height="250px" width="350px" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe></p>
      </div>
      <br>
    </div>

  
          <div class="pasteventinfo">
            <br><br>
          <PastEventInfo></PastEventInfo>
          </div>
</template>

<script>
import PastEventInfo from '@/components/PastEventInfo.vue';

export default {
  name: 'EventView',
  components: {
    PastEventInfo
  },
  data() {
    return {
      photos: [
        require('@/assets/img00.jpg'),
        require('@/assets/img01.jpg'),
        require('@/assets/img02.jpg'),
        // 追加の写真パスをここに追加
      ],
      currentPhotoIndex: 0
    };
  },
  mounted() {
    // 最初の写真を表示
    this.changePhoto();

    // 5秒ごとに写真を切り替える
    setInterval(this.changePhoto, 5000);
  },
  methods: {
    changePhoto() {
      this.currentPhotoIndex = (this.currentPhotoIndex + 1) % this.photos.length;
    }
  }
};
</script>

<style scoped>
.event{
  text-align: left;
  padding-left: 20px;
  margin-bottom: 200px;
  color: var(--secondary-color);
  background-color: var(--primary-color);
  width: 100%;
  box-sizing: border-box;
}
.event::after{
  content: "";
  position: fixed;
  left: 0;
  top: 300px;
  width: 100%;
  height: 100%;
  background: url(../assets/img00.jpg) no-repeat center/cover;
  z-index: -1;
  opacity: 0;
  animation: fadeIn 3s forwards;
}

@keyframes fadeIn {
  from{opacity: 0;}
  to{opacity: 1;}  
}

.eventinfo{
  margin-bottom: 300px;
  text-align: left;
  padding: 20px;
  box-sizing: border-box;
  color: var(--secondary-color);
  background-color: var(--third-color);
  width: 100%;
}
.map{
  text-align: center;
}

.pasteventinfo{
  text-align: left;
  box-sizing: border-box;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  padding: 50px auto 50px auto;
  background-color: var(--third-color);
  width: 100%;
  margin-bottom: 200px;
  overflow: scroll;
}

@media screen and (max-width: 640px) {
  .event{
    overflow-x: hidden;
  }

  .pasteventinfo{
    overflow-x: scroll;
  }
}
</style>