<template>
  <div class="thisyear">
    
    <h1>第94回わせだ寄席</h1>
          <h2>学生限定・入場無料！！</h2>
          <p>
            開催決定<br><br>
          </p>
          <h3>Coming soon...</h3>
    </div>


    <div class="map">
      <h2>会場はこちら</h2>
    <p><iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12958.939256065507!2d139.71047874879133!3d35.708142861252554!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188d1cb93f8869%3A0x6067ebc016cae4d5!2z5bCP6YeO6KiY5b-16Kyb5aCC!5e0!3m2!1sja!2sjp!4v1720270856110!5m2!1sja!2sjp" height="250px" width="350px" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe></p>
    </div>

</template>

<script>
export default {
  name: 'ThisYear',
  components: {
  },
  data() {
    return {
      photos: [
        require('@/assets/img00.jpg'),
        require('@/assets/img01.jpg'),
        require('@/assets/img02.jpg'),
        // 追加の写真パスをここに追加
      ],
      currentPhotoIndex: 0
    };
  },
  mounted() {
    // 最初の写真を表示
    this.changePhoto();

    // 5秒ごとに写真を切り替える
    setInterval(this.changePhoto, 5000);
  },
  methods: {
    changePhoto() {
      this.currentPhotoIndex = (this.currentPhotoIndex + 1) % this.photos.length;
    }
  }
};
</script>

<style scoped>
.thisyear{
  margin-top: 50px;
  padding-left: 20px;
  box-sizing: border-box;
}
.map{
  text-align: center;
}


</style>